<template>
  <VSheet color="transparent" class="ask-section">
    <VSheet class="d-flex flex-column justify-center pa-15 mb-10 text-center">
      <p class="heading font-nunito font-weight-regular black--text mb-0">
        {{ $vuetify.lang.t('$vuetify.views.landing.askSection.heading') }}
      </p>
      <p class="subheading font-nunito font-weight-regular primary--text" :class="showScrollTo? 'mb-4 mb-md-8' : 'mb-0'">
        {{ $vuetify.lang.t('$vuetify.views.landing.askSection.subheading') }}
      </p>

      <p class="form-link-text" v-if="showScrollTo">
        <!-- TODO: Find more accurate way to scroll -->
        <a class="form-link black--text font-weight-regular font-weight-bold" @click.stop="scrollTo('contact-form')">
          <span class="text-decoration-underline">
            {{ $vuetify.lang.t('$vuetify.views.landing.askSection.formLink') }}
          </span>
        </a>
      </p>
    </VSheet>

    <div class="horizontal-spacer dark"></div>

    <div id="contact-form"></div>
    <ContactForm :narrow="narrow" enable-recaptcha/>
  </VSheet>
</template>

<script>
import { defineComponent } from 'vue';
import ContactForm from '@/components/ContactForm.vue';
import { scrollToElement } from '../../../utils/helpers';

export default defineComponent({
  name: 'AskSection',

  components: {
    ContactForm,
  },

  props: {
    showScrollTo: {
      type: [String, Boolean],
      default: 'contact-form',
    },

    narrow: {
      type: Boolean,
      default: true,
    },
  },


  methods: {
    scrollTo: scrollToElement,
  },
})
</script>

<style lang="scss" scoped>
.ask-section {
  .heading {
    line-height: 1.35;
    font-size: em(21);

    @include media-breakpoint-up(md) {
      font-size: em(34);
    }

    @include media-breakpoint-up(lg) {
      font-size: em(42);
    }
  }

  .subheading {
    line-height: 1.35em;
    font-size: em(30);

    @include media-breakpoint-up(md) {
      font-size: em(48);
    }

    @include media-breakpoint-up(lg) {
      font-size: em(60);
    }
  }

  .form-link-text {
    margin-bottom: 60px;

    @include media-breakpoint-up(md) {
      margin-bottom: 162px;
    }
  }

  .form-link {
    font-size: 18px;
    line-height: 1.33em;
  }
}
</style>
